import React from 'react';
import { ReactComponent as Image } from './logo-v2.svg'

const Logo = ({ style }) => {
    return (
        <div style={{...style}}>
            <Image />
        </div>
    );
};

export default Logo;
