import React from 'react';

export default class Services extends React.PureComponent {

  getProgress = () => this.props.progress ? this.props.progress + '%' : 0;

  render = () => {
    var className = "tab-list-item"
    if (this.props.isActive) {
      className += " active";
    }
    return (
      <div className={className}>
        <div className="title">
          <h3 onClick={this.props.onClick} >{this.props.title}</h3>
          { this.props.isActive &&
            <div className="underline" style={{width:this.getProgress()}}/>
          }
        </div>
        { !this.props.isLastTab &&
          <span className="vertical-separator"/>
        }
      </div>
    );
  }
}
