import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Logo from '../../assets/logo';
import Scroll from 'react-scroll-to-element';

import ConfidentialityModal from "../Confidentiality";

export default class Nav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lineHeight: this.getWhiteLineHeight(),
      isOpen: false,
      showConfidentiality: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.adjustNavBarBackground);
    window.addEventListener('popstate', this.handleBackButton);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.adjustNavBarBackground);
    window.removeEventListener('popstate', this.handleBackButton);
  }

  handleBackButton = (event) => {
    event.preventDefault();

    if(this.state.showConfidentiality){
      this.closeConfidentialityModal()
    }
  };

  adjustNavBarBackground = () => {
    this.setState({lineHeight: this.getWhiteLineHeight()});
  }

  getWhiteLineHeight = () => {
    const parallaxCoefficient = window.scrollY/3;
    const initialHeight = 5;
    const maxHeight = 80;
    const currentHeight = initialHeight + parallaxCoefficient < maxHeight ? initialHeight + parallaxCoefficient : maxHeight;

    return currentHeight;
  }

  toggleMenu = () => {
    this.setState({...this.state, isOpen: !this.state.isOpen});
  }

  openConfidentialityModal = () => {
    this.setState({ ...this.state, showConfidentiality: true, isOpen: false});
    this.props.navigate(`/${this.props.lang}`)
  }

  closeConfidentialityModal = () => {
    this.setState({ ...this.state, showConfidentiality: false});
  }

  render() {
    return (
      <nav ref="nav">
        <div className="white-line dark nav-content" style={{height: this.state.isOpen ? '100vh' : this.state.lineHeight}}> {this.renderContent()} </div>
        <div className="light nav-content"> {this.renderContent()} </div>
      </nav>
    );
  }

  renderContent = () => {
    return (
      <Container>
        <Row className="row align-items-center">
          <Col lg="2" md="2" sm="6" xs="6">
            <Scroll>
              <Logo/>
            </Scroll>
          </Col>
          <Col  className="d-flex flex-row-reverse" lg="10" md="10" sm="6" xs="6">
            <ul>
              <li onClick={() => this.setState({...this.state, isOpen: false})}>
                <Scroll type="id" element="services" offset={-80}>
                  <div onClick={this.toggleMenu}>
                    <div>{this.props.texts.services}</div>
                    <div className="undeline"/>
                  </div>
                </Scroll>
              </li>
              <li onClick={() => this.setState({...this.state, isOpen: false})}>
                <Scroll type="id" element="about" offset={-80}>
                  <div onClick={this.toggleMenu}>
                    <div>{this.props.texts.about}</div>
                    <div className="undeline"/>
                  </div>
                </Scroll>
              </li>
              <li onClick={() => this.setState({...this.state, isOpen: false})}>
                <Scroll type="id" element="footer" offset={-80}>
                  <div>
                    <div>{this.props.texts.contact}</div>
                    <div className="undeline"/>
                  </div>
                </Scroll>
              </li>

              <li onClick={() => this.openConfidentialityModal()}>
                  <div>
                    <div>{this.props.texts.confidentiality}</div>
                    <div className="undeline"/>
                  </div>
              </li>
            </ul>

            <div className={this.state.isOpen ? "hamburger hamburger--spin is-active" : "hamburger hamburger--spin" } onClick={this.toggleMenu}>
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>

          </Col>
        </Row>
        <ConfidentialityModal closeConfidentialityModal={this.closeConfidentialityModal} showConfidentiality={this.state.showConfidentiality} />
      </Container>
    );
  }
}
