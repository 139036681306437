import React from 'react';

export default class Logo extends React.PureComponent {
  render() {
    return (
        <svg onClick={this.props.onClick} className="logo" width="900px" height="150px" viewBox="0 0 900 150" version="1.1" xmlns="http://www.w3.org/2000/svg">
<path fill="none" opacity="1.000000" stroke="none" d="M337.000000,171.000000 C224.666687,171.000000 112.833374,171.000000 1.000045,171.000000 C1.000030,114.333366 1.000030,57.666725 1.000015,1.000068 C173.999939,1.000045 346.999878,1.000045 519.999878,1.000023 C519.999878,57.666595 519.999878,114.333191 519.999939,170.999893 C459.166656,171.000000 398.333344,171.000000 337.000000,171.00000 M171.500031,29.999857 C163.740463,29.999857 155.980896,29.999857 148.388306,29.999857 C148.388306,61.410412 148.388306,92.131828 148.388306,122.744644 C184.046341,122.744644 219.315475,122.744644 254.700073,122.744644 C254.700073,91.767838 254.700073,61.047340 254.700073,29.999857 C227.100922,29.999857 199.800461,29.999857 171.500031,29.99985 M366.384644,123.000450 C368.152954,123.000450 369.921265,123.000450 371.649506,123.000450 C371.649506,91.668251 371.649506,60.945740 371.649506,30.396826 C335.907990,30.396826 300.518494,30.396826 265.266907,30.396826 C265.266907,61.399487 265.266907,92.004265 265.266907,123.000450
	C298.825684,123.000450 332.118774,123.000450 366.384644,123.000450
M436.500000,122.000465
	C453.921967,122.000465 471.343903,122.000465 488.613434,122.000465
	C488.613434,91.259277 488.613434,61.204338 488.613434,31.253529
	C452.956726,31.253529 417.687469,31.253529 382.298157,31.253529
	C382.298157,61.562393 382.298157,91.616417 382.298157,122.000465
	C400.229767,122.000465 417.864899,122.000465 436.500000,122.000465
M82.500000,122.000717
	C100.255226,122.000717 118.010452,122.000717 135.613785,122.000717
	C135.613785,90.592888 135.613785,59.871227 135.613785,29.253283
	C99.956947,29.253283 64.687531,29.253283 29.297812,29.253283
	C29.297812,60.228870 29.297812,90.949631 29.297812,122.000717
	C46.896194,122.000717 64.198097,122.000717 82.500000,122.000717
M481.378601,132.000000
	C483.565063,131.894714 485.751526,131.789429 487.937958,131.684143
	C488.009338,130.993164 488.080688,130.302185 488.152069,129.611206
	C486.209198,129.407471 484.266327,129.025726 482.323425,129.025360
	C332.834778,128.997696 183.346130,128.999786 33.857479,129.002792
	C32.358479,129.002823 30.821907,128.860840 29.378214,129.154953
	C28.765615,129.279770 28.347841,130.360886 27.064117,132.000000
	C178.929840,132.000000 329.668304,132.000000 481.378601,132.000000
z"/>
<path fill="#00A2E8" opacity="1.000000" stroke="none"
	d="
M172.000015,29.999857
	C199.800461,29.999857 227.100922,29.999857 254.700073,29.999857
	C254.700073,61.047340 254.700073,91.767838 254.700073,122.744644
	C219.315475,122.744644 184.046341,122.744644 148.388306,122.744644
	C148.388306,92.131828 148.388306,61.410412 148.388306,29.999857
	C155.980896,29.999857 163.740463,29.999857 172.000015,29.999857
M201.818893,87.279549
	C201.314102,87.831642 200.880692,88.763153 200.292862,88.873756
	C196.696594,89.550339 193.005768,90.619774 189.480286,90.257118
	C188.353714,90.141235 186.949295,86.094223 186.853531,83.796631
	C186.527740,75.981384 186.609848,68.141075 186.809875,60.317337
	C186.863098,58.235733 187.895798,56.179173 188.476257,54.120132
	C185.901169,54.120132 182.628845,54.120132 179.117920,54.120132
	C183.300873,57.421730 183.589935,87.933640 179.408310,92.068642
	C185.698257,92.068642 191.694916,91.875702 197.671005,92.142921
	C201.109772,92.296692 202.123047,90.760170 201.818893,87.279549
z"/>
<path fill="#00A2E8" opacity="1.000000" stroke="none"
	d="
M365.898254,123.000450
	C332.118774,123.000450 298.825684,123.000450 265.266907,123.000450
	C265.266907,92.004265 265.266907,61.399487 265.266907,30.396826
	C300.518494,30.396826 335.907990,30.396826 371.649506,30.396826
	C371.649506,60.945740 371.649506,91.668251 371.649506,123.000450
	C369.921265,123.000450 368.152954,123.000450 365.898254,123.000450
M312.246063,66.325111
	C313.298187,69.376129 314.350281,72.427155 315.725800,76.416031
	C310.886780,75.877693 307.118744,75.458504 302.843048,74.982834
	C302.860138,74.922165 302.506104,76.177200 302.310211,76.871704
	C307.375488,78.852783 313.917511,73.159004 316.894165,80.167084
	C318.434265,83.793022 323.633392,88.639229 316.723755,92.875290
	C320.656464,92.875290 324.390167,92.875290 327.593781,92.875290
	C326.163147,90.338173 324.336456,87.869598 323.292023,85.105431
	C319.827576,75.936203 316.705811,66.637688 313.267853,57.457954
	C312.886536,56.439751 311.318115,55.866154 310.300201,55.086365
	C309.994507,56.460316 309.205017,57.945309 309.489258,59.183861
	C309.996796,61.395020 311.152466,63.457420 312.246063,66.325111
M294.575470,92.882301
	C297.110535,92.882301 299.645630,92.882301 300.986084,92.882301
	C300.682556,89.895653 300.407074,87.185112 300.190979,85.058983
	C298.467163,87.198372 296.479584,89.665092 294.575470,92.882301
M308.405548,61.617115
	C307.864838,61.880093 307.324097,62.143074 306.783356,62.406052
	C306.905701,62.613632 307.028046,62.821209 307.150391,63.028790
	C307.667297,62.693779 308.184204,62.358765 308.405548,61.617115
M307.405487,64.617126
	C306.864777,64.880096 306.324066,65.143066 305.783356,65.406036
	C305.893158,65.621742 306.002930,65.837448 306.112732,66.053154
	C306.642151,65.710037 307.171600,65.366928 307.405487,64.617126
M306.405518,67.617149
	C305.864807,67.880119 305.324097,68.143097 304.783386,68.406067
	C304.893158,68.621765 305.002960,68.837463 305.112732,69.053162
	C305.642181,68.710045 306.171631,68.366936 306.405518,67.617149
z"/>
<path fill="#00A2E8" opacity="1.000000" stroke="none"
	d="
M436.000000,122.000465
	C417.864899,122.000465 400.229767,122.000465 382.298157,122.000465
	C382.298157,91.616417 382.298157,61.562393 382.298157,31.253529
	C417.687469,31.253529 452.956726,31.253529 488.613434,31.253529
	C488.613434,61.204338 488.613434,91.259277 488.613434,122.000465
	C471.343903,122.000465 453.921967,122.000465 436.000000,122.000465
M427.635803,62.373821
	C429.215515,67.039619 430.795227,71.705406 432.639282,77.151833
	C428.012054,76.082710 423.636108,78.980148 420.175446,74.680244
	C419.790771,75.967804 419.433228,77.164543 419.234253,77.830574
	C424.158600,79.725494 430.719086,74.486961 433.753632,80.875862
	C435.451111,84.449715 440.856537,89.549622 433.850281,93.828949
	C437.666595,93.828949 441.406067,93.828949 444.261810,93.828949
	C442.557617,90.253174 440.520172,86.623337 439.057526,82.775017
	C435.996033,74.719933 433.306152,66.523834 430.268188,58.459282
	C429.884430,57.440552 428.326202,56.864262 427.312103,56.082981
	C426.983521,57.448444 426.550537,58.801041 426.379883,60.185966
	C426.315338,60.709820 426.856445,61.308304 426.453888,62.001095
	C425.579224,62.462051 424.704559,62.923008 423.829865,63.383968
	C424.081116,63.694984 424.332367,64.006004 424.583618,64.317024
	C425.427643,63.497448 426.271698,62.677872 427.635803,62.373821
M417.150574,88.164673
	C417.316223,87.293159 417.481873,86.421654 417.805908,84.716888
	C415.445343,88.289627 413.589081,91.099075 411.718018,93.930954
	C414.212524,93.930954 416.699707,93.930954 418.706085,93.930954
	C418.143402,92.253067 417.579742,90.572289 417.150574,88.164673
M423.660034,66.915398
	C423.987610,66.612892 424.315155,66.310379 424.642700,66.007874
	C424.374817,65.902466 423.928711,65.646660 423.869263,65.717102
	C423.579163,66.060913 423.392303,66.491829 423.660034,66.915398
M422.406738,68.868179
	C422.291382,69.177155 422.176056,69.486137 422.060730,69.795120
	C422.574402,69.636627 423.088043,69.478134 423.601715,69.319626
	C423.356140,69.098518 423.110535,68.877403 422.406738,68.868179
z"/>
<path fill="#00A2E8" opacity="1.000000" stroke="none"
	d="
M82.000000,122.000717
	C64.198097,122.000717 46.896194,122.000717 29.297812,122.000717
	C29.297812,90.949631 29.297812,60.228870 29.297812,29.253283
	C64.687531,29.253283 99.956947,29.253283 135.613785,29.253283
	C135.613785,59.871227 135.613785,90.592888 135.613785,122.000717
	C118.010452,122.000717 100.255226,122.000717 82.000000,122.000717
M88.329781,79.261482
	C89.793930,81.683235 91.411102,84.027435 92.686699,86.544739
	C94.645164,90.409622 97.387924,92.113831 101.057243,90.864388
	C98.921982,87.673431 96.764549,84.429115 94.585861,81.199135
	C92.382668,77.932816 90.158432,74.680695 87.938606,71.425461
	C93.638878,68.549324 95.553658,65.333420 94.394157,60.583260
	C93.163010,55.539547 89.687561,53.063198 83.583603,52.980583
	C79.595467,52.926605 75.606003,52.970402 71.439331,52.970402
	C75.323158,57.351128 75.252029,87.130310 71.506905,90.830490
	C74.619186,90.830490 77.708092,90.830490 80.630577,90.830490
	C76.025810,85.010223 79.040855,78.483070 78.075218,72.167648
	C83.363144,72.073616 85.703651,75.298630 88.329781,79.261482
z"/>
<path fill="#F1565E" opacity="1.000000" stroke="none"
	d="
M480.892700,132.000000
	C329.668304,132.000000 178.929840,132.000000 27.064117,132.000000
	C28.347841,130.360886 28.765615,129.279770 29.378214,129.154953
	C30.821907,128.860840 32.358479,129.002823 33.857479,129.002792
	C183.346130,128.999786 332.834778,128.997696 482.323425,129.025360
	C484.266327,129.025726 486.209198,129.407471 488.152069,129.611206
	C488.080688,130.302185 488.009338,130.993164 487.937958,131.684143
	C485.751526,131.789429 483.565063,131.894714 480.892700,132.000000
z"/>
<path fill="#DBF0FB" opacity="1.000000" stroke="none"
	d="
M201.935181,87.524155
	C202.123047,90.760170 201.109772,92.296692 197.671005,92.142921
	C191.694916,91.875702 185.698257,92.068642 179.408310,92.068642
	C183.589935,87.933640 183.300873,57.421730 179.117920,54.120132
	C182.628845,54.120132 185.901169,54.120132 188.476257,54.120132
	C187.895798,56.179173 186.863098,58.235733 186.809875,60.317337
	C186.609848,68.141075 186.527740,75.981384 186.853531,83.796631
	C186.949295,86.094223 188.353714,90.141235 189.480286,90.257118
	C193.005768,90.619774 196.696594,89.550339 200.292862,88.873756
	C200.880692,88.763153 201.314102,87.831642 201.935181,87.524155
z"/>
<path fill="#EDF6FC" opacity="1.000000" stroke="none"
	d="
M312.141602,65.953568
	C311.152466,63.457420 309.996796,61.395020 309.489258,59.183861
	C309.205017,57.945309 309.994507,56.460316 310.300201,55.086365
	C311.318115,55.866154 312.886536,56.439751 313.267853,57.457954
	C316.705811,66.637688 319.827576,75.936203 323.292023,85.105431
	C324.336456,87.869598 326.163147,90.338173 327.593781,92.875290
	C324.390167,92.875290 320.656464,92.875290 316.723755,92.875290
	C323.633392,88.639229 318.434265,83.793022 316.894165,80.167084
	C313.917511,73.159004 307.375488,78.852783 302.310211,76.871704
	C302.506104,76.177200 302.860138,74.922165 302.843048,74.982834
	C307.118744,75.458504 310.886780,75.877693 315.725800,76.416031
	C314.350281,72.427155 313.298187,69.376129 312.141602,65.953568
z"/>
<path fill="#EDF0F9" opacity="1.000000" stroke="none"
	d="
M294.533752,92.507057
	C296.479584,89.665092 298.467163,87.198372 300.190979,85.058983
	C300.407074,87.185112 300.682556,89.895653 300.986084,92.882301
	C299.645630,92.882301 297.110535,92.882301 294.533752,92.507057
z"/>
<path fill="#09A6EA" opacity="1.000000" stroke="none"
	d="
M308.553345,61.820435
	C308.184204,62.358765 307.667297,62.693779 307.150391,63.028790
	C307.028046,62.821209 306.905701,62.613632 306.783356,62.406052
	C307.324097,62.143074 307.864838,61.880093 308.553345,61.820435
z"/>
<path fill="#09A6EA" opacity="1.000000" stroke="none"
	d="
M307.553284,64.820465
	C307.171600,65.366928 306.642151,65.710037 306.112732,66.053154
	C306.002930,65.837448 305.893158,65.621742 305.783356,65.406036
	C306.324066,65.143066 306.864777,64.880096 307.553284,64.820465
z"/>
<path fill="#09A6EA" opacity="1.000000" stroke="none"
	d="
M306.553284,67.820488
	C306.171631,68.366936 305.642181,68.710045 305.112732,69.053162
	C305.002960,68.837463 304.893158,68.621765 304.783386,68.406067
	C305.324097,68.143097 305.864807,67.880119 306.553284,67.820488
z"/>
<path fill="#EDF6FC" opacity="1.000000" stroke="none"
	d="
M427.121490,61.872772
	C426.856445,61.308304 426.315338,60.709820 426.379883,60.185966
	C426.550537,58.801041 426.983521,57.448444 427.312103,56.082985
	C428.326202,56.864262 429.884430,57.440552 430.268188,58.459282
	C433.306152,66.523834 435.996033,74.719933 439.057526,82.775017
	C440.520172,86.623337 442.557617,90.253174 444.261810,93.828949
	C441.406067,93.828949 437.666595,93.828949 433.850281,93.828949
	C440.856537,89.549622 435.451111,84.449715 433.753632,80.875862
	C430.719086,74.486961 424.158600,79.725494 419.234253,77.830574
	C419.433228,77.164543 419.790771,75.967804 420.175446,74.680244
	C423.636108,78.980148 428.012054,76.082710 432.639282,77.151833
	C430.795227,71.705406 429.215515,67.039619 427.310913,62.168137
	C426.985992,61.962456 427.121490,61.872768 427.121490,61.872772
z"/>
<path fill="#EDF0F9" opacity="1.000000" stroke="none"
	d="
M417.083344,88.528091
	C417.579742,90.572289 418.143402,92.253067 418.706085,93.930954
	C416.699707,93.930954 414.212524,93.930954 411.718018,93.930954
	C413.589081,91.099075 415.445343,88.289627 417.805908,84.716888
	C417.481873,86.421654 417.316223,87.293159 417.083344,88.528091
z"/>
<path fill="#09A6EA" opacity="1.000000" stroke="none"
	d="
M427.050842,61.910378
	C426.271698,62.677872 425.427643,63.497448 424.583618,64.317024
	C424.332367,64.006004 424.081116,63.694984 423.829865,63.383968
	C424.704559,62.923008 425.579224,62.462051 426.787689,61.936932
	C427.121490,61.872768 426.985992,61.962456 427.050842,61.910378
z"/>
<path fill="#09A6EA" opacity="1.000000" stroke="none"
	d="
M423.414490,66.903656
	C423.392303,66.491829 423.579163,66.060913 423.869263,65.717102
	C423.928711,65.646660 424.374817,65.902466 424.642700,66.007874
	C424.315155,66.310379 423.987610,66.612892 423.414490,66.903656
z"/>
<path fill="#09A6EA" opacity="1.000000" stroke="none"
	d="
M422.635834,68.762238
	C423.110535,68.877403 423.356140,69.098518 423.601715,69.319633
	C423.088043,69.478134 422.574402,69.636627 422.060730,69.795120
	C422.176056,69.486137 422.291382,69.177155 422.635834,68.762238
z"/>
<path fill="#EFF9FC" opacity="1.000000" stroke="none"
	d="
M88.154205,78.941223
	C85.703651,75.298630 83.363144,72.073616 78.075218,72.167648
	C79.040855,78.483070 76.025810,85.010223 80.630577,90.830490
	C77.708092,90.830490 74.619186,90.830490 71.506905,90.830490
	C75.252029,87.130310 75.323158,57.351128 71.439331,52.970402
	C75.606003,52.970402 79.595467,52.926605 83.583603,52.980583
	C89.687561,53.063198 93.163010,55.539547 94.394157,60.583260
	C95.553658,65.333420 93.638878,68.549324 87.941010,71.423904
	C90.158432,74.680695 92.382668,77.932816 94.585861,81.199135
	C96.764549,84.429115 98.921982,87.673431 101.057243,90.864388
	C97.387924,92.113831 94.645164,90.409622 92.686699,86.544739
	C91.411102,84.027435 89.793930,81.683235 88.154205,78.941223
M79.546242,70.938995
	C86.794250,70.830208 89.935326,68.552513 90.032875,63.334763
	C90.154282,56.841827 85.516083,53.323013 77.917015,54.696564
	C78.173691,59.968533 78.435005,65.335655 79.546242,70.938995
z"/>
<path fill="#08A5E9" opacity="1.000000" stroke="none"
	d="
M79.121277,70.820885
	C78.435005,65.335655 78.173691,59.968533 77.917015,54.696564
	C85.516083,53.323013 90.154282,56.841827 90.032875,63.334763
	C89.935326,68.552513 86.794250,70.830208 79.121277,70.820885
z"/>
</svg>

    );
  }
}
