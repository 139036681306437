import CustomBackground from '../assets/custom-background.jpg';
import SelfServiceBackground from '../assets/self-service-background.jpg';
import DiagnosticBackground from '../assets/diagnostic-background.jpg';
import Renaud from '../assets/renaud.jpg';
import Genevieve from '../assets/genevieve.jpg';

export default {
  "nav": {
    "services": "nos services",
    "about": "à propos",
    "contact": "contact",
    "confidentiality": "politique de confidentialié"

  },
  "header": {
    "text": "L’expertise en droit des affaires entièrement dédiée aux PME de tout le Québec et à ceux qui les soutiennent financièrement. Un micro cabinet formé d’un avocat et d’une comptable unis pour répondre autrement et réellement aux besoins juridiques, comptables, financiers et d’accompagnement des entreprises. Une offre distinctive de services et de produits jumelée à une tarification compétitive et prévisible. Nous vous présentons RLAA."
  },
  "services": {
    "title": "Nous pouvons vous aider",
    "services": [
      {
        "title": "Sur mesure",
        "descriptionParagraphs": ["Nous offrons une vaste gamme de services juridiques pouvant être pleinement adaptés afin de vous offrir des solutions juridiques pragmatiques à vos problématiques d’affaires."],
        "backgroundImage": CustomBackground,
        "elementLists": [
          [
            "Financement d’entreprise (dette et équité)",
            "Droit corporatif et réorganisation",
            "Révision ou rédaction d’ententes commerciales",
            "Capital de risque et de développement",
            "Coopératives"
          ],
          [
            "Achat et vente d’entreprise",
            "Convention entre actionnaires",
            "Contrat d’emploi, contrat de service et non-concurrence",
            "Démarrage d’entreprises",
            "Conseil et accompagnement"
          ]
        ]
      },
      // {
      //   "title": "Libre-service",
      //   "descriptionParagraphs": ["Vous avez un besoin de nature juridique, mais vous ne souhaitez pas retenir les services d’un avocat? Consultez notre banque de documents standardisés. Sans être exhaustive, nous croyons que cette banque offre une réponse aux besoins juridiques de base d’une entreprise. Vous n’avez qu’à payer et emporter votre document. Vous pourrez le modifier et l’adapter à votre guise. Au besoin, nous serons là pour vous supporter. Allez-y, jetez un coup d’œil."],
      //   "backgroundImage": SelfServiceBackground,
      //   "elementLists": []
      // },

      // Diagnostic et accompagnement, removed by client's request
      // {
      //   "title": "Diagnostic et accompagnement",
      //   "descriptionParagraphs": [
      //     "Plusieurs dirigeants d’entreprises sous-estiment la complexité de mettre en place un financement, une acquisition ou une vente d’entreprise. Le manque de préparation est souvent la source d’un parcours cahoteux et peut engendrer une perte de focus sur les enjeux quotidiens et une surcharge de travail importante.",
      //     "L’entrepreneur et son équipe devront répondre à des questions pointues sur une foule de sujets tels que la rentabilité par produits ou clients, la robustesse des systèmes de gestion, la complémentarité de l’équipe de gestion et la relève, les assurances, les risques environnementaux et légaux, les procédures et la gestion des opérations, la structure de capital et l’endettement, etc.",
      //     "L’une des façons de diminuer ces risques est de poser un diagnostic préalable sur la situation de l’entreprise. Ce diagnostic a pour objectif de faire une revue stratégique de l’ensemble des facettes de l’entreprise afin d’identifier ses forces et ses faiblesses. Cette démarche structurée permettra à l’entrepreneur d’avoir un regard nouveau sur son entreprise et de pouvoir mieux exécuter le projet de financement, d’achat ou de vente. Vous serez préparé, structuré et efficace. Nous serons à vos côtés tout au long de ce processus en mettant notre expérience et notre cœur à l’ouvrage. Tout comme vous."
      //   ],
      //   "backgroundImage": DiagnosticBackground,
      //   "elementLists": []
      // }
    ],
  },
  "about": {
    "title": "Voici qui nous sommes",
    "members": [
      {
        "name": "Renaud",
        "fullname": "Renaud Lebuis, Avocat",
        "descriptionParagraphs": [
          "J’ai acquis mes premières années d’expérience en cabinet avant de me joindre à Desjardins Capital, un gestionnaire spécialisé dans le capital d’investissement. J’y ai travaillé plus de 14 ans et j’ai eu la chance de côtoyer un grand nombre d’entrepreneurs tout en étant confronté à des enjeux complexes et variés. J’ai surtout pu développer une expertise en droit des affaires notamment dans le financement, l’achat et la vente d’entreprise, la rédaction et la négociation de contrats, la réorganisation d’entreprises, la mise en place de convention entre actionnaires et différents régimes d’intéressement en faveur d’employés. J’ai également participé à l’accompagnement de coopératives œuvrant dans divers secteurs. Je crois que pour proposer les bonnes solutions, il faut écouter afin de comprendre les enjeux. ",
          // "En fondant RLAA avec ma conjointe, j’ai voulu mettre mes compétences au service des entrepreneurs tout en conciliant les défis liés à une vie familiale atypique. "
        ],
        "image": Renaud
      },
      {
        "name": "Geneviève",
        "fullname": "Geneviève Lehoux, CPA, CGA",
        "descriptionParagraphs": ["À la suite de mes études à HEC Montréal et l’obtention de mon titre comptable, j’ai travaillé au sein d’entreprises de taille mondiale ainsi qu’auprès de PME. C’est d’ailleurs avec ces dernières que j’ai le plus d’affinité. L’expérience acquise au long des 20 dernières années me permettent d’avoir une vision globale du cycle comptable et des enjeux financiers auxquels les PME peuvent être confrontées. Je considère qu’il est important d’établir les bons indicateurs pour suivre la rentabilité de l’entreprise et de permettre une croissance saine."],
        "image": Genevieve
      }
    ]
  },
  "contact" : {
    "title" : [
      "N'hésitez pas à nous",
      "contacter pour discuter"
    ],
    "name": "Nom",
    "nameError": "Nom invalide",
    "email": "Courriel",
    "emailError": "Courriel invalide",
    "message": "Message",
    "send": "Envoyer",
    "sending": "En cours...",
    "invalidForm": "Formulaire invalide",
    "failed": "L'envoie a échoué",
    "emailSent": "Merci de votre courriel"
  },
  "footer": {
    "connect": "Connectez avec nous",
    "emailTitle": "C:",
    "phoneTitle": "T:",
    "addressTitle": "A:",
    "email": "renaud.lebuis@rlaa.ca",
    "phone": "514-207-7345",
    "address": [
      "1549 boulevard Jolibourg",
      "Laval (Québec)",
      "H7Y1Y2"
    ]
  }
}
