import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Logo from '../../assets/logo';
import LinkedinLogo from '../../assets/linkedin-logo.svg';
import SkypeLogo from '../../assets/skype-logo.png';
import FacetimeLogo from '../../assets/facetime-logo.svg';
import LogoV2 from "../../assets/Logo-v2";

export default class Nav extends React.PureComponent {

  render = () => {
    return (
      <footer id="footer">
        <Container>
          <Row className="row justify-content-center">
            <Col lg="12" md="12" sm="12" xs="12">
              <div className="horizontal-separator" />
            </Col>
          </Row>
          <Row className="row align-items-center h-100 content-row">
            <Col className="d-flex justify-content-center" lg="4" md="4" sm="12" xs="12" >
              <LogoV2 style={{ height:"100px"}}/>
            </Col>
            <Col className="middle-content" lg="4" md="4" sm="12" xs="12">
              {/*<div className="separator"/>*/}
              {/*<div>*/}
              {/*  <p className="text-center">{this.props.texts.connect}</p>*/}
              {/*  <div className="d-flex justify-content-center">*/}
              {/*    <button><img src={LinkedinLogo}/></button>*/}
              {/*    <button><img src={SkypeLogo}/></button>*/}
              {/*    <button><img src={FacetimeLogo}/></button>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="separator"/>*/}
            </Col>
            <Col className="d-flex justify-content-center" lg="4" md="4" sm="12" xs="12">
              <p className="text-left contact-info">
                <strong>{this.props.texts.emailTitle}</strong> {this.props.texts.email} <br/>
                <strong>{this.props.texts.phoneTitle}</strong> {this.props.texts.phone} <br/>
                <strong>{this.props.texts.addressTitle}</strong> {this.props.texts.address[0]} <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.texts.address[1]} <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.texts.address[2]}
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }

}
