import React, { useMemo } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components'
import {Viewer, Worker} from "@react-pdf-viewer/core";

import filePath from "../../assets/PolitiqueConfidentialite.pdf";
import {AiOutlineCloseCircle} from "react-icons/ai";

import useWindowDimensions from "../../hooks/useWindowDimensions";

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function ConfidentialityModal ({ showConfidentiality, closeConfidentialityModal}) {
    const { width, phoneDimensions } = useWindowDimensions();

    const isPhone = width <= phoneDimensions

    const customStyle = useMemo(() => {
        return {
            content: {
                top: '51%',
                left: `50%`,
                right: 'auto',
                bottom: '-40%',
                transform: 'translate(-50%, -50%)',
            },
        }
    }, [])

    return (
            <Modal
                isOpen={showConfidentiality}
                onRequestClose={closeConfidentialityModal}
                style={customStyle}
                contentLabel="Example Modal"
            >
                <Container isPhone={isPhone}>
                    <CloseButton>
                        <Close size={25} onClick={closeConfidentialityModal}/>
                    </CloseButton>
                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js`} pageLayout={{ width: '700px'}}>
                        <Viewer fileUrl={filePath} defaultScale={isPhone? 0.55:1.5}/>
                    </Worker>
                </Container>

            </Modal>
        );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  
  height: 800px;
  width: ${props => props.isPhone? '40vh': '100vh'};
  
  overflow: hidden;
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  
  margin: 0 0 10px 0;
`;

const Close = styled(AiOutlineCloseCircle)`
  color: grey;
  
  &:hover{
    cursor: pointer;
    opacity: 0.5;
  }
`



