import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export default class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {parallaxBackgroundPosition: this.getBackgroundPos()};
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.adjustBackgroundParallax);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.adjustBackgroundParallax);
  }

  adjustBackgroundParallax = () => {
    this.setState({parallaxBackgroundPosition: this.getBackgroundPos()})
  }

  getBackgroundPos = () => {
    const scrollY = window.scrollY;
    const initialYPos = - 20;
    const currentPos = initialYPos + scrollY/3;
    return "center " + currentPos + "px";
  }

  render = () => {
    return (
      <div className="header light" style={{backgroundPosition:this.state.parallaxBackgroundPosition}}>
        <div className="dark-overlay"/>
        <Container>
          <Row className="align-items-end">
            <Col lg="10" md="12" sm="12" xs="12">
              <h1>RLAA</h1>
              <p>
                {this.props.texts.text}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
