import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import TabList from '../Common/TabList';

const IMG_CLASSNAMES = {
  ABSOLUTE_TOP: "about-img absolute-top d-lg-block d-none",
  FIXED: "about-img fixed d-lg-block d-none",
  ABSOLUTE_BOTTOM: "about-img absolute-bottom d-lg-block d-none"
}

export default class About extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = { currentMemberIndex: 0, imgClassName: IMG_CLASSNAMES.ABSOLUTE_TOP };
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.adjustImgClassName);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.adjustImgClassName);
  }

  adjustImgClassName = () => {
    const top = this.refs.about.getBoundingClientRect().top;
    const height = this.refs.about.getBoundingClientRect().height;
    const paddingTop = 100;
    const paddingBottom = 130;
    const imgHeight = this.refs.img.getBoundingClientRect().height;
    const navHeight = 80;
    const bottomTreshold = -(height-imgHeight-paddingBottom-navHeight-paddingTop);

    if (top > navHeight && this.state.imgClassName !== IMG_CLASSNAMES.ABSOLUTE_TOP) {
      this.setState({ ...this.state, imgClassName: IMG_CLASSNAMES.ABSOLUTE_TOP })
    } else if (top <= navHeight && top > bottomTreshold && this.state.imgClassName !== IMG_CLASSNAMES.FIXED) {
      this.setState({ ...this.state, imgClassName: IMG_CLASSNAMES.FIXED })
    } else if (top <= bottomTreshold && this.state.imgClassName !== IMG_CLASSNAMES.ABSOLUTE_BOTTOM) {
      this.setState({ ...this.state, imgClassName: IMG_CLASSNAMES.ABSOLUTE_BOTTOM })
    }
  }

  render = () => {
    return (
      <div id="about" className="about dark" ref="about">
        <div ref="img" className={this.state.imgClassName} style={{backgroundImage: "url(" + this.getCurrentMember().image + ")" }}/>
        <Container>
          <Row className="">
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="about-img d-lg-none" style={{backgroundImage: "url(" + this.getCurrentMember().image + ")" }}/>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <h2>{this.props.texts.title}</h2>
              <TabList items={this.props.texts.members.map(s => s.name)} activeItemIndex={this.state.currentMemberIndex} onItemClick={this.setCurrentMember}/>
              <p className="fullname-text">{this.getCurrentMember().fullname}</p>
              {this.getCurrentMember().descriptionParagraphs.map(p => <p>{p}</p>)}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  getCurrentMember = () => this.props.texts.members[this.state.currentMemberIndex];

  setCurrentMember = index => this.setState({...this.state, currentMemberIndex: index});

}
