import React from 'react';
import TabList from '../Common/TabList';
import { isBrowser } from "react-device-detect";

export default class Services extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      isHovering: false,
      currentServiceIndex: 0,
      currentTimer: 0,
      timerDuration: 8
    };

    if (isBrowser) {
      this.updateTimer();
    }
  }

  // Rendering methods

  render = () => {
    return (
      <div id="services" className="services light" style={{backgroundImage: "url(" + this.getCurrentService().backgroundImage + ")" }}>
        <div className="dark-overlay" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <div className="services-header">
            <h2>{this.props.texts.title}</h2>
            <TabList items={this.props.texts.services.map(s => s.title)} activeItemIndex={this.state.currentServiceIndex} onItemClick={this.changeCurrentService} progress={this.getProgress()}/>
          </div>
          <div className="services-content">
            {this.getCurrentService().descriptionParagraphs.map(paragraph => <p>{paragraph}</p>)}
            <div className="list-holder">
              {this.getCurrentService().elementLists.map(list => this.renderList(list))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderList = list => {
    return (
      <ul>
        {list.map(element => <li>{element}</li>)}
      </ul>
    );
  }

  // Carousel methods

  setNextServiceIndex = () => {
    const nextIndex = this.state.currentServiceIndex+1 >= this.props.texts.services.length ? 0 : this.state.currentServiceIndex+1;
    this.setState({...this.state, currentTimer: 0, currentServiceIndex: nextIndex});
  }

  updateTimer = () => {
    var time = this.state.isHovering ? this.state.currentTimer : this.state.currentTimer + 0.05;
    this.setState({...this.state, currentTimer: time });
    if (this.state.currentTimer >= this.state.timerDuration) {
      this.setNextServiceIndex();
    }
    setTimeout(this.updateTimer, 50);
  }

  getProgress = () => 100 * this.state.currentTimer / this.state.timerDuration;

  getCurrentService = () => this.props.texts.services[this.state.currentServiceIndex];

  // EVENT HANDLERS

  onMouseEnter = () => this.setState({...this.state, isHovering: true});
  onMouseLeave = () => this.setState({...this.state, isHovering: false});
  changeCurrentService = index => this.setState({...this.state, currentTimer: 0, currentServiceIndex: index});
}
