import React from 'react';
import { render } from 'react-dom';
import {BrowserRouter, Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import getLocalizedText from './localizables';
import Nav from './components/Nav';
import Header from './components/Header';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';

// CSS Imports
import "../node_modules/hamburgers/dist/hamburgers.min.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './style.css';

export default function App({lang}) {
    const navigate = useNavigate();

    const texts = getLocalizedText(lang);
    return (
      <div>
          <Nav texts={texts.nav} navigate={navigate} lang={lang}/>
          <Header texts={texts.header}/>
          <Services texts={texts.services}/>
          <About texts={texts.about}/>
          {/*<Contact texts={texts.contact}/>*/}
          <Footer texts={texts.footer}/>
      </div>
    );
}

render(
  <BrowserRouter>
    <Routes>
      <Route exact path="/en" element={<App lang="en"/>}/>
      <Route exact path="/fr" element={<App lang="fr"/>} />
      <Route path="*" element={<Navigate to="/fr" replace />} />
    </Routes>
  </BrowserRouter>
, document.getElementById('root'));
