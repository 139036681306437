import React from 'react';
import Item from './Item';

export default class Services extends React.PureComponent {
  render = () => {
    return (
      <ul className="tab-list">
        { this.props.items.map(this.renderItem) }
      </ul>
    );
  }

  renderItem = (item, index) => <Item
                                  key={index}
                                  title={item}
                                  progress={this.props.progress}
                                  isActive={this.props.activeItemIndex === index}
                                  isLastTab={this.props.items.length-1 === index}
                                  onClick={() => this.props.onItemClick(index)}/>
}
