import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const EMAIL_SEND_STATES = {
  NONE: "NONE",
  INVALID_FORM: "INVALID_FORM",
  SENDING: "SENDING",
  FAILED: "FAILED",
  SENT: "SENT"
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default class About extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      sendingState: EMAIL_SEND_STATES.NONE
    };
  }

  render = () => {
    return (
      <div id="contact" className="contact dark">
        <Container>
          <Row className="justify-content-end">
            <Col lg="4" md="6" sm="12" xs="12">
              <h2> {this.props.texts.title[0]} <br/> {this.props.texts.title[1]} </h2>
            </Col>
            <Col className={this.nameHolderClassName()} lg="4" md="6" sm="12" xs="12">
              <label>{this.props.texts.name}</label>
              <input type="text" name="name" value={this.state.name} onChange={this.onInputChange}/>
              <p className="error">{this.props.texts.nameError}</p>
            </Col>
            <Col className={this.emailHolderClassName()} lg="4" md="6" sm="12" xs="12">
              <label>{this.props.texts.email}</label>
              <input type="email" name="email" value={this.state.email} onChange={this.onInputChange}/>
              <p className="error">{this.props.texts.emailError}</p>
            </Col>
          </Row>
          <Row className="justify-content-end" >
            <Col className="form-col" lg="8" md="6" sm="12" xs="12">
              <label>{this.props.texts.message}</label>
              <textarea name="message" value={this.state.message} onChange={this.onInputChange}/>
            </Col>
          </Row>
          <Row className="justify-content-end" >
            <Col className={this.buttonHolderClassName()} lg="12" md="12" sm="12" xs="12">
              { this.state.sendingState!==EMAIL_SEND_STATES.SENT &&
                <div className="d-flex flex-row-reverse">
                  <button
                    onClick={this.sendEmail}
                    disabled={this.state.sendingState===EMAIL_SEND_STATES.SENDING || (this.state.sendingState===EMAIL_SEND_STATES.INVALID_FORM && !this.hasValidForm())}>
                    {this.state.sendingState===EMAIL_SEND_STATES.SENDING ? this.props.texts.sending : this.props.texts.send}
                  </button>
                </div>
              }
              <p className="error">{this.getFormError()}</p>
              { this.state.sendingState===EMAIL_SEND_STATES.SENT &&
                <p>{this.props.texts.emailSent}</p>
              }
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  // Event handler methdos

  onInputChange = (event) => {
    this.state[event.target.name] = event.target.value;
    this.forceUpdate();
  }

  sendEmail = async () => {
    if (!this.hasValidForm()) {
      this.setState({...this.state, sendingState: EMAIL_SEND_STATES.INVALID_FORM});
      return;
    }

    this.setState({...this.state, sendingState: EMAIL_SEND_STATES.SENDING});

    const url = "https://lc3byb763i.execute-api.ca-central-1.amazonaws.com/Prod/send";
    const { name, email, message } = this.state;
    const body = JSON.stringify({
      toEmails: ["nicstaubin@gmail.com", "renaud.lebuis@rlaa.ca"],
      subject: "RLAA Site Web | Nouveau contact form",
      message: `name: ${name} \n\nemail: ${email} \n\nmessage: \n${message}`
    });
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body
    }

    // Send email
    try {
      await fetch(url, options);
      this.setState({...this.state, sendingState: EMAIL_SEND_STATES.SENT});
    } catch(error) {
      console.error(error);
      this.setState({...this.state, sendingState: EMAIL_SEND_STATES.FAILED});
    }

  }

  // Form validation methods

  hasValidName = () => this.state.name.length > 2;

  nameHolderClassName = () => this.state.sendingState !== EMAIL_SEND_STATES.INVALID_FORM || this.hasValidName() ? "form-col" : "form-col has-error";

  hasValidEmail = () => EMAIL_REGEX.test(String(this.state.email).toLowerCase());

  emailHolderClassName = () => this.state.sendingState !== EMAIL_SEND_STATES.INVALID_FORM || this.hasValidEmail() ? "form-col" : "form-col has-error";

  hasValidForm = () => this.hasValidName() && this.hasValidEmail();

  buttonHolderClassName = () => (this.state.sendingState !== EMAIL_SEND_STATES.INVALID_FORM && this.state.sendingState !== EMAIL_SEND_STATES.FAILED) || this.hasValidForm() ? "form-col" : "form-col has-error";

  getFormError = () => {
    if (this.state.sendingState === EMAIL_SEND_STATES.INVALID_FORM) return this.props.texts.invalidForm;
    if (this.state.sendingState === EMAIL_SEND_STATES.FAILED) return this.props.texts.failed;
  }

}
